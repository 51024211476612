import http from "@/api";

const state = {
  status: {
    Bsmandataries: {
      loading: false,
    },
    B2smandataries: {
      loading: false,
    },
  },
  endpoints: {
    mandatary: "/api/mandatary/",
    comex: "/api/comex/",
    auditor: "/api/auditor/",
  },
  // MANDATARY
  Bmandataries: {},
  B2mandataries: {},
  Bsmandataries: {},
  B2smandataries: {},
  // COMEX
  Bcomexs: {},
  B2comexs: {},
  Bscomexs: {},
  B2scomexs: {},
  dataCSV: {},
  // AUDITOR
  Bauditors: {},
  B2auditors: {},
  Bsauditors: {},
  B2sauditors: {},
};

const mutations = {
  ["SET_LOADING"](state, { action, status }) {
    state.status[action].loading = status;
  },
  // MANDATARY
  ["BENCHMANDATARIES"](state, mandataries) {
    state.Bmandataries = mandataries;
  },
  ["BENCH2MANDATARIES"](state, mandataries) {
    state.B2mandataries = mandataries;
  },
  ["STATSMANDATARIES"](state, stats) {
    state.Bsmandataries = stats;
  },
  ["STATS2MANDATARIES"](state, stats) {
    state.B2smandataries = stats;
  },
  // COMEX
  ["BENCHCOMEXS"](state, comexs) {
    state.Bcomexs = comexs;
  },
  ["BENCH2COMEXS"](state, comexs) {
    state.B2comexs = comexs;
  },
  ["STATSCOMEXS"](state, stats) {
    state.Bscomexs = stats;
  },
  ["STATS2COMEXS"](state, stats) {
    state.B2scomexs = stats;
  },
  // AUDITOR
  ["BENCHAUDITORS"](state, auditors) {
    state.Bauditors = auditors;
  },
  ["BENCH2AUDITORS"](state, auditors) {
    state.B2auditors = auditors;
  },
  ["STATSAUDITORS"](state, stats) {
    state.Bsauditors = stats;
  },
  ["STATS2AUDITORS"](state, stats) {
    state.B2sauditors = stats;
  },
  ["DATACSV"](state, data) {
    state.dataCSV = data;
  },
};

const getters = {
  isLoading: (state) => (action) => {
    return !!state.status[action]?.loading;
  },
};

const actions = {
  // MANDATARY
  async getMandatary({ state }, uid) {
    const response = await http.get(state.endpoints.mandatary + uid + "/");
    return response;
  },
  async getMandataries({ state }, payload) {
    const response = await http.get(state.endpoints.mandatary, payload);
    return response;
  },
  async getStatsMandataries({ state }, payload) {
    const response = await http.get(
      state.endpoints.mandatary + "stats/",
      payload
    );
    return response;
  },
  async getStatsMandatariesFree({ state }, payload) {
    const response = await http.get(
      state.endpoints.mandatary + "stats/free/",
      payload
    );
    return response;
  },
  async benchMandataries({ dispatch, commit }, payload) {
    const response = await dispatch("getMandataries", payload);
    commit("BENCHMANDATARIES", response.data);
    return response;
  },
  async bench2Mandataries({ dispatch, commit }, payload) {
    const response = await dispatch("getMandataries", payload);
    commit("BENCH2MANDATARIES", response.data);
    return response;
  },
  async nextMandataries({ state, commit }) {
    if (state.Bmandataries.next) {
      const response = await http.get(state.Bmandataries.next);
      commit("BENCHMANDATARIES", response.data);
      return response;
    }
  },
  async next2Mandataries({ state, commit }) {
    if (state.B2mandataries.next) {
      const response = await http.get(state.B2mandataries.next);
      commit("BENCH2MANDATARIES", response.data);
      return response;
    }
  },
  async previousMandataries({ state, commit }) {
    if (state.Bmandataries.previous) {
      const response = await http.get(state.Bmandataries.previous);
      commit("BENCHMANDATARIES", response.data);
      return response;
    }
  },
  async previous2Mandataries({ state, commit }) {
    if (state.B2mandataries.previous) {
      const response = await http.get(state.B2mandataries.previous);
      commit("BENCH2MANDATARIES", response.data);
      return response;
    }
  },
  async statsMandataries({ dispatch, commit }, payload) {
    commit("SET_LOADING", { action: "Bsmandataries", status: true });

    try {
      const response = await dispatch("getStatsMandataries", payload);
      commit("STATSMANDATARIES", response.data);
      return response;
    } catch (error) {
      throw error;
    } finally {
      commit("SET_LOADING", {
        action: "Bsmandataries",
        status: false,
      });
    }
  },
  async stats2Mandataries({ dispatch, commit }, payload) {
    commit("SET_LOADING", { action: "B2smandataries", status: true });

    try {
      const response = await dispatch("getStatsMandataries", payload);
      commit("STATS2MANDATARIES", response.data);
      return response;
    } catch (error) {
      throw error;
    } finally {
      commit("SET_LOADING", {
        action: "B2smandataries",
        status: false,
      });
    }
  },

  // COMEX
  async getComex({ state }, uid) {
    const response = await http.get(state.endpoints.comex + uid + "/");
    return response;
  },
  async getComexs({ state }, payload) {
    const response = await http.get(state.endpoints.comex, payload);
    return response;
  },
  async getStatsComexs({ state }, payload) {
    const response = await http.get(state.endpoints.comex + "stats/", payload);
    return response;
  },
  async getStatsComexsFree({ state }, payload) {
    const response = await http.get(
      state.endpoints.comex + "stats/free/",
      payload
    );
    return response;
  },
  async benchComexs({ dispatch, commit }, payload) {
    const response = await dispatch("getComexs", payload);
    commit("BENCHCOMEXS", response.data);
    return response;
  },
  async bench2Comexs({ dispatch, commit }, payload) {
    const response = await dispatch("getComexs", payload);
    commit("BENCH2COMEXS", response.data);
    return response;
  },
  async nextComexs({ state, commit }) {
    if (state.Bcomexs.next) {
      const response = await http.get(state.Bcomexs.next);
      commit("BENCHCOMEXS", response.data);
      return response;
    }
  },
  async next2Comexs({ state, commit }) {
    if (state.B2comexs.next) {
      const response = await http.get(state.B2comexs.next);
      commit("BENCH2COMEXS", response.data);
      return response;
    }
  },
  async previousComexs({ state, commit }) {
    if (state.Bcomexs.previous) {
      const response = await http.get(state.Bcomexs.previous);
      commit("BENCHCOMEXS", response.data);
      return response;
    }
  },
  async previous2Comexs({ state, commit }) {
    if (state.B2comexs.previous) {
      const response = await http.get(state.B2comexs.previous);
      commit("BENCH2COMEXS", response.data);
      return response;
    }
  },
  async statsComexs({ dispatch, commit }, payload) {
    const response = await dispatch("getStatsComexs", payload);
    commit("STATSCOMEXS", response.data);
    return response;
  },
  async stats2Comexs({ dispatch, commit }, payload) {
    const response = await dispatch("getStatsComexs", payload);
    commit("STATS2COMEXS", response.data);
    return response;
  },
  // AUDITOR
  async getAuditor({ state }, uid) {
    const response = await http.get(state.endpoints.auditor + uid + "/");
    return response;
  },
  async getAuditors({ state }, payload) {
    const response = await http.get(state.endpoints.auditor, payload);
    return response;
  },
  async getStatsAuditors({ state }, payload) {
    const response = await http.get(
      state.endpoints.auditor + "stats/",
      payload
    );
    return response;
  },
  async getStatsAuditorsFree({ state }, payload) {
    const response = await http.get(
      state.endpoints.auditor + "stats/free/",
      payload
    );
    return response;
  },
  async benchAuditors({ dispatch, commit }, payload) {
    const response = await dispatch("getAuditors", payload);
    commit("BENCHAUDITORS", response.data);
    return response;
  },
  async bench2Auditors({ dispatch, commit }, payload) {
    const response = await dispatch("getAuditors", payload);
    commit("BENCH2AUDITORS", response.data);
    return response;
  },
  async nextAuditors({ state, commit }) {
    if (state.Bauditors.next) {
      const response = await http.get(state.Bauditors.next);
      commit("BENCHAUDITORS", response.data);
      return response;
    }
  },
  async next2Auditors({ state, commit }) {
    if (state.B2auditors.next) {
      const response = await http.get(state.B2auditors.next);
      commit("BENCH2AUDITORS", response.data);
      return response;
    }
  },
  async previousAuditors({ state, commit }) {
    if (state.Bauditors.previous) {
      const response = await http.get(state.Bauditors.previous);
      commit("BENCHAUDITORS", response.data);
      return response;
    }
  },
  async previous2Auditors({ state, commit }) {
    if (state.B2auditors.previous) {
      const response = await http.get(state.B2auditors.previous);
      commit("BENCH2AUDITORS", response.data);
      return response;
    }
  },
  async statsAuditors({ dispatch, commit }, payload) {
    const response = await dispatch("getStatsAuditors", payload);
    commit("STATSAUDITORS", response.data);
    return response;
  },
  async stats2Auditors({ dispatch, commit }, payload) {
    const response = await dispatch("getStatsAuditors", payload);
    commit("STATS2AUDITORS", response.data);
    return response;
  },

  async csvComexs({ commit, state }, payload) {
    var url = state.endpoints.comex + "csv/";
    var payload = payload != undefined ? payload : {};
    const response = await http.get(url, payload);
    commit("DATACSV", response.data);
    return response.data;
  },
  async csv2Comexs({ commit, state }, payload) {
    var url = state.endpoints.comex + "csv/";
    var payload = payload != undefined ? payload : {};
    const response = await http.get(url, payload);
    commit("DATACSV", response.data);
    return response.data;
  },
  async csvMandataries({ commit, state }, payload) {
    var url = state.endpoints.mandatary + "csv/";
    var payload = payload != undefined ? payload : {};
    const response = await http.get(url, payload);
    commit("DATACSV", response.data);
    return response.data;
  },
  async csv2Mandataries({ commit, state }, payload) {
    var url = state.endpoints.mandatary + "csv/";
    var payload = payload != undefined ? payload : {};
    const response = await http.get(url, payload);
    commit("DATACSV", response.data);
    return response.data;
  },
  async csvAuditors({ commit, state }, payload) {
    var url = state.endpoints.auditor + "csv/";
    var payload = payload != undefined ? payload : {};
    const response = await http.get(url, payload);
    commit("DATACSV", response.data);
    return response.data;
  },
  async csv2Auditors({ commit, state }, payload) {
    var url = state.endpoints.auditor + "csv/";
    var payload = payload != undefined ? payload : {};
    const response = await http.get(url, payload);
    commit("DATACSV", response.data);
    return response.data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
